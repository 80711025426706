import React, { Component } from 'react';
import BackgroundImage from 'gatsby-background-image';

import { useSpring, animated } from 'react-spring';
// import { Spring } from 'react-spring/renderprops';

// import styled from 'styled-components';
// import tw from 'twin.macro';

export default ({ children, backgroundImage, location, style, className, big = false }) => {
	// const springProps = useSpring({
	// 	from: { height: location.pathname === '/' ? 600 : 730 },
	// 	to: { height: location.pathname === '/' ? 730 : 600 },
	// });

	// const childrenSpringProps = useSpring({
	// 	from: { opacity: 1 },
	// 	to: { opacity: 1 }
	// });

	const AnimatedBackgroundImage = animated(BackgroundImage);

	return (
		<AnimatedBackgroundImage
			Tag="section"
			className={`bg-cover bg-center overflow-hidden h-500 md:h-550 ${
				big ? 'xl:h-730' : 'xl:h-600'
			}`}
			// , { ...springProps }
			style={{ ...style }}
			// backgroundColor={`rgba(0, 70, 121, .6)`}
			fluid={backgroundImage.childImageSharp.fluid}
			loading="eager"
			fadeIn={true}
			durationFadeIn={10}>
			{/* style={childrenSpringProps} */}
			<animated.div className="h-full">{children}</animated.div>
		</AnimatedBackgroundImage>
	);
};
